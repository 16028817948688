// Your custom variables
// Fonts
$roboto-font-path:     "/css/fonts/";
$primary-color:        #00718a !default;
$red-base:             #db4432 !default;
$font-weight-regular:  300 !default;

$input-label-color:    #333333 !default;

$input-form-control-padding-top:       6px !default;
$input-form-control-padding-bottom:    4px !default;

$material-select-dropdown-content-li-line-height: 1.1rem;

$checkbox-label-before-after-background-color:   #fff !default;