// Your custom styles
// Turning off all links on hover transitions
a {
  &:hover {
    transition: none;
  }
}

// Material checkbox custom styles
.form-check-input[type="checkbox"],
label.btn input[type="checkbox"] {

  // Checkbox feature
  + label:before,
   &:not(.filled-in) + label:after {
    background: $checkbox-label-before-after-background-color;
  }

  &:checked {
    + label:before {
      background: transparent;
    }
  }

}

.select-wrapper input.active {
  box-shadow: 0 1px 0 0 $primary-color;
  border-bottom: 1px solid $primary-color;
}